<template>
  <CCard body-wrapper>
    <CRow
      align-vertical="center"
      class="mb-3"
    >
      <CCol col="auto">
        <h1>
          Настройки
        </h1>
      </CCol>
    </CRow>
    <CForm
      class="mt-5"
      @submit.prevent="edit"
    >
      <CElementCover
        v-if="loadingSettings"
        :opacity="0.8"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
        />
      </CElementCover>
      <template v-else>
        <CInput
          v-for="({ key, title }) in settings"
          :key="key"
          :label="title"
          :value.sync="editForm.data[key]"
        />
        <CRow>
          <CCol col="auto">
            <CButton
              color="primary"
              type="submit"
              :disabled="editForm.inProcessing"
            >
              <template v-if="editForm.inProcessing">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span class="sr-only">Подождите...</span>
              </template>
              <template v-else>
                Сохранить изменения
              </template>
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CForm>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import urls from '../../../router/urls';

export default {
  name: 'List',

  data() {
    return {
      editForm: {
        data: {},
        inProcessing: false,
      },
      loadingSettings: false,
      settings: [],
    };
  },

  created() {
    this.getSettings();
  },

  methods: {
    async edit() {
      this.editForm.inProcessing = true;

      try {
        await this.$axios.put(urls.urlGeneralSettings, this.editForm.data);
      } catch (e) {
        if (e.response) {
          await Swal.fire({
            icon: 'error',
            text: 'При сохранении настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.',
            title: 'Ошибка!',
          });
        }
      } finally {
        this.editForm.inProcessing = false;
      }
    },

    async getSettings() {
      this.loadingSettings = true;

      try {
        const { data } = await this.$axios.get(urls.urlGeneralSettings);

        data.items.forEach(({ key, title, value }) => {
          this.editForm.data[key] = value;

          this.settings.push(
            {
              key,
              title,
              value,
            },
          );
        });
      } catch (e) {
        if (e.response) {
          await Swal.fire({
            icon: 'error',
            text: 'При загрузке настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.',
            title: 'Ошибка!',
          });

          await this.$router.push({ name: 'admin.reestrs.list' });
        }
      } finally {
        this.loadingSettings = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
